<template>
  <div>
    <v-card class="pb-5">
      <v-toolbar flat class="pt-">
        <v-col cols="12" lg="9" md="6" sm="6" class="mt-5">
          <v-toolbar-title
            class="error--text text-uppercase font-weight-bold text--darken-1 justify-center"
          >
            <v-icon class="mr-2 mt-n1" color="error"
              >mdi-file-edit</v-icon
            >
            Registration
          </v-toolbar-title>
          <p class="grey--text ml-8 mt-n2">
            Supplier registration and prequalification
          </p>
        </v-col>
        <v-col cols="12" lg="3" md="6" sm="6" class="justify-center">
          <v-chip
            color="error"
            class="mt-n1 mr-auto"
            right
            label
            outlined
            v-if="computedClosingDate"
          >
            <v-avatar left>
              <v-icon small>mdi-clock-alert-outline</v-icon>
            </v-avatar>
            <countdown :time="computedClosingDate">
              <template slot-scope="props"
                >{{ props.days }} days, {{ props.hours }} hours,
                {{ props.minutes }} minutes,
                {{ props.seconds }} seconds.</template
              >
            </countdown>
          </v-chip>
        </v-col>
      </v-toolbar>
      <v-divider></v-divider>
      <!-- Part A  -->
      <form class="px-5">
        <v-row>
          <v-col class="" cols="6" lg="6" md="6">
            <h4>A. SELECT SUPPLIER CATEGORY(s)</h4>
            <v-divider class="my-5"></v-divider>
            <p>Select all the categories you want to register</p>
            <v-card
              class="mx-auto overflow-y-auto"
              max-width="100%"
              max-height="350"
              color="grey"
              outlined
              v-if="items.length > 0"
            >
              <v-list>
                <v-list-item-group
                  v-model="selectedCategories"
                  @change="itemSelected"
                  multiple
                >
                  <template v-for="(item, i) in items">
                    <v-divider
                      v-if="!item.title"
                      :key="`divider-${i}`"
                    ></v-divider>
                    <v-list-item v-else :key="`item-${i}`" :value="item.id">
                      <template>
                        <v-list-item-content>
                          <v-list-item-title v-text="item.title">
                          </v-list-item-title>
                          <span class="caption">{{ item.description }}</span>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-checkbox
                            v-model="selectedCategories"
                            :input-value="item.selected"
                            :value="item.id"
                            color="primary"
                          ></v-checkbox>
                        </v-list-item-action>
                      </template>
                    </v-list-item>
                  </template>
                </v-list-item-group>
              </v-list>
            </v-card>
            <p class="caption" v-else>Loading categories...</p>
          </v-col>
          <v-col class="" cols="6" lg="6" md="6">
            <h4>
              B. REQUIRED DOCUMENT(s)
              <!-- <span class="grey--text">(Mandatory requirement)</span> -->
            </h4>
            <v-divider class="my-5"></v-divider>
            <p>Upload all required documents</p>
            <v-card
              class="mx-auto overflow-y-auto px-5"
              max-width="100%"
              max-height="350"
              outlined
              v-if="documents.length > 0"
            >
              <div
                v-for="(item, i) in documents"
                :key="i"
                cols="12"
                sm="12"
                dense
              >
                <v-tooltip top v-if="item.attachment">
                  <template v-slot:activator="{ on, attrs }">
                    <span class="d-inline-block mb-1" v-bind="attrs" v-on="on">
                      <a
                        :href="item.attachment"
                        target="_blank"
                        class="d-block primary--text text--darken-1 text-decoration-none"
                      >
                        <v-icon x-large color="primary">mdi-file-word</v-icon>
                      </a>
                    </span>
                  </template>
                  <span
                    >Click to download sample :
                    {{ truncate(item.name, 30) }}</span
                  >
                </v-tooltip>
                <v-tooltip top v-else>
                  <template v-slot:activator="{ on, attrs }">
                    <span class="d-inline-block mb-1" v-bind="attrs" v-on="on">
                      <a
                        href="3"
                        target="_blank"
                        class="d-block primary--text text--darken-1 text-decoration-none"
                      >
                        <v-icon x-large color="grey">mdi-file-alert</v-icon>
                      </a>
                    </span>
                  </template>
                  <span>No sample document</span>
                </v-tooltip>

                <v-tooltip
                  top
                  v-if="
                    documentUploaded(
                      preq_uploaded_required_docs,
                      'document_id',
                      item.id
                    )
                  "
                >
                  <template v-slot:activator="{ on, attrs }">
                    <span class="d-inline-block mb-1" v-bind="attrs" v-on="on">
                      <a
                        :href="
                          get_item_url(preq_uploaded_required_docs, item.id)
                        "
                        target="_blank"
                        class="d-block success--text text--darken-1 text-decoration-none"
                      >
                        <v-icon x-large color="success"
                          >mdi-arrow-down-bold-circle</v-icon
                        >
                      </a>
                    </span>
                  </template>
                  <span
                    >Click to download your uploaded :
                    {{ truncate(item.name, 30) }}</span
                  >
                </v-tooltip>
                <v-tooltip top v-else>
                  <template v-slot:activator="{ on, attrs }">
                    <span class="d-inline-block mb-1" v-bind="attrs" v-on="on">
                      <a
                        target="_blank"
                        class="d-block success--text text--darken-1 text-decoration-none"
                      >
                        <v-icon x-large color="grey"
                          >mdi-arrow-down-bold-circle</v-icon
                        >
                      </a>
                    </span>
                  </template>
                  <span>Not yet uploaded</span>
                </v-tooltip>
                <v-file-input
                  show-size
                  :label="item.name"
                  outlined
                  dense
                  append-icon="mdi-file-upload"
                  prepend-icon=""
                  color="grey"
                  class="mb-n2"
                  v-model="files['file' + item.id]"
                  @change="handleFileUpload(files['file' + item.id], item)"
                >
                  <template v-slot:selection="{ text }">
                    <v-chip label color="success darken-1" small>
                      {{ text }}
                    </v-chip>
                  </template>

                  <template v-slot:label>
                    <span
                      class="input__label success--text"
                      v-if="
                        documentUploaded(
                          preq_uploaded_required_docs,
                          'document_id',
                          item.id
                        )
                      "
                    >
                      <v-icon color="success" class="mt-n2"
                        >mdi-check-all</v-icon
                      >
                      {{ item.name }}
                    </span>
                    <span class="input__label" v-else>
                      {{ truncate(item.name, 40) }}
                      <v-icon small color="error" class="mt-n2"
                        >mdi-star-outline</v-icon
                      ></span
                    >
                  </template>
                </v-file-input>
              </div>
            </v-card>
            <p v-else class="caption">No documents to be uploaded</p>
          </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row>
          <v-col cols="12" lg="6" md="6" sm="6"> </v-col>
          <v-col cols="12" lg="3" md="3" sm="3">
            <v-form ref="form" v-model="valid">
              <v-text-field
                v-model="password"
                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[rules.required, rules.min]"
                :type="show1 ? 'text' : 'password'"
                name="input-10-1"
                label="Corfirm with password"
                hint="At least 6 characters"
                counter
                @click:append="show1 = !show1"
                color="grey"
                outlined
                dense
                class="my-2"
              ></v-text-field>
            </v-form>
          </v-col>
          <v-col cols="12" lg="3" md="3" sm="3">
            <v-btn
              dark
              class="ml-auto my-2 d-flex text-capitalize"
              color="primary"
              @click="submitApplication"
            >
              Submit Application<v-icon>mdi-save</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </form>
    </v-card>
  </div>
</template>

<script>
import ConfigService from "../../services/config.service";
import SupplierCategoryService from "../../services/supplier_category.service";
import TenderService from "../../services/tender.service";

export default {
  name: "TenderApplication",
  components: {},
  data() {
    return {
      valid: false,
      item: null,
      items: [],
      documents: [],
      e6: 1,
      checkbox: "",
      selectedCategories: [],
      config: {},
      files: {},

      show1: false,
      password: "",
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 6 || "Min 6 characters",
        emailMatch: () => `The email and password you entered don't match`,
      },
    };
  },

  computed: {
    timeRemaining() {
      return this.timer;
    },
    computedClosingDate() {
      let result = this.getTimeRemaining(
        this.config.prequalification_closing_date
      ).total;
      if (result < 0) {
        return 0;
      } else {
        return result;
      }
    },
    user() {
      return this.$store.state.auth.user;
    },
    preq_uploaded_required_docs: {
      get: function () {
        return this.$store.state.user.preq_uploaded_required_docs;
      },
      set: function (value) {
        this.$store.dispatch("user/setPreqUploadedRequiredDocs", value);
      },
    },
  },
  created() {
    this.index();
    this.systemConfig();
    this.getPreqUploadedRequiredDocs();
  },
  methods: {
    getPreqUploadedRequiredDocs() {
      return TenderService.getPreqUploadedRequiredDocs(this.user.id).then(
        (response) => {
          if (response.status == 200) {
            console.log(response.data);
            this.preq_uploaded_required_docs = response.data.data;
            this.$store.dispatch(
              "user/setPreqUploadedRequiredDocs",
              response.data.data
            );
            this.loading = false;
          } else {
            this.loading = false;
            console.log(response.data.error);
          }
        },
        (error) => {
          console.log(error);
        }
      );
    },
    truncate(str, n) {
      return str.length > n ? str.substr(0, n - 1) + "..." : str;
    },
    arraySearch(array, key, value) {
      let filteredArray = [];
      for (var i = 0; i < array.length; i++) {
        if (array[i][key] == value) {
          filteredArray = array[i];
        }
      }
      return filteredArray;
    },
    documentUploaded(array, key, value) {
      for (var i = 0; i < array.length; i++) {
        if (array[i][key] == value) {
          return true;
        }
      }
      return false;
    },
    get_item_url(array, value) {
      for (var i = 0; i < array.length; i++) {
        if (array[i]["document_id"] == value) {
          return array[i]["content"];
        }
      }
    },
    reserve() {},
    async getFileObject(input) {
      // let blob = await fetch(input.uploaded);
      let blob = await fetch(input.uploaded);
      return blob.size;
      // return blob;
    },

    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    async handleFileUpload(file, item) {
      console.log("uploading...");
      let formData = {};
      formData["applicant_id"] = this.user.id;
      formData["document_id"] = item.id;
      await this.getBase64(file).then((data) => (formData["content"] = data));
      this.file = null;
      TenderService.addPreqRequiredDocument(formData).then(
        (response) => {
          if (response.status == 200) {
            this.$store.dispatch("alert/success", "Document uploaded!");
            this.file = null;
            this.getPreqUploadedRequiredDocs();
            this.loading = false;
          } else {
            this.$store.dispatch("alert/error", response.message);
            this.loading = false;
          }
        },
        (error) => {
          this.loading = false;
          if (error.response.status == 422) {
            console.log(error.response.data.errors);
            this.message = error.response.data.message;
          } else {
            this.message =
              (error.response && error.response.data) ||
              error.message ||
              error.toString() ||
              "Internal Server Error";
          }
          this.$store.dispatch("alert/error", this.message);
          this.loading = false;
        }
      );
    },
    itemSelected() {
      console.log(this.selectedCategories);
      let data = {};
      data.categories = this.selectedCategories;
      return SupplierCategoryService.getDocs(data).then(
        (response) => {
          console.log(response);
          if (response.status == 200) {
            console.log(response.data.data);
            this.documents = response.data.data;
            this.loading = false;
          } else {
            this.loading = false;
            console.log(response.data.error);
          }
        },
        (error) => {
          this.document = [];
          console.log(error);
        }
      );
    },
    index() {
      return SupplierCategoryService.index().then(
        (response) => {
          console.log(response.data.data);
          if (response.status == 200) {
            this.items = response.data.data;
            this.loading = false;
          } else {
            this.loading = false;
            console.log(response.data.error);
          }
        },
        (error) => {
          this.items = [];
          console.log(error);
        }
      );
    },
    submitApplication() {
      this.$refs.form.validate();
      if (this.valid == true) {
        let conf = confirm(
          "Are you sure you want to submit application? you cannot undo this action."
        );
        if (conf) {
          this.loading = true;
          let formData = {};
          formData.password = this.password;
          formData.applicant_id = this.user.id;
          formData.categories = this.selectedCategories;
          return TenderService.submitPrequalificationApplication(formData).then(
            (response) => {
              console.log(response);
              if (response.status == 200) {
                this.$store.dispatch(
                  "alert/success",
                  "Tender application submitted!"
                );
                this.$router.replace("/marketplace");
              } else if (response.status == 201) {
                this.$store.dispatch("alert/error", "Invalid password!");
              } else if (response.status == 202) {
                this.$store.dispatch(
                  "alert/error",
                  "Missing all mandatory documents!"
                );
              } else {
                this.$store.dispatch(
                  "alert/error",
                  "Internal server error, please check the form and try again!"
                );
              }
            },
            (error) => {
              console.log(error);
              this.$store.dispatch("alert/error", error.response);
            }
          );
        }
      }
    },
    systemConfig() {
      return ConfigService.index().then(
        (response) => {
          setTimeout(() => {
            if (response.status == 200) {
              this.config = response.data.data;
              this.dialog = false;
              this.pageReady = true;
            } else {
              console.log(response.data.error);
            }
          }, 1000);
        },
        (error) => {
          console.log(error);
        }
      );
    },
    createDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return new Date(year, month, day);
    },
    getTimeRemaining(endtime) {
      endtime = this.createDate(endtime);
      console.log(endtime);
      const total =
        Date.parse(endtime) - Date.parse(new Date()) - 27 * 24 * 60 * 60 * 1000;
      const seconds = Math.floor((total / 1000) % 60);
      const minutes = Math.floor((total / 1000 / 60) % 60);
      const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
      const days = Math.floor(total / (1000 * 60 * 60 * 24));
      return {
        total,
        days,
        hours,
        minutes,
        seconds,
      };
    },
  },
};
</script>
